import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HowdenAlertService } from '@howdeniberia/core-front';
import { IRiesgoDTO, IStepDTO } from 'src/app/core/models/api';
import { RiesgosService } from 'src/app/core/services/api';
import { RisksDataMapService } from 'src/app/core/services/core';
import { HelpImpactoComponent } from '../help-impacto/help-impacto.component';
import { HelpProbabilidadComponent } from '../help-probabilidad/help-probabilidad.component';
import { HelpRiesgoComponent } from '../help-riesgo/help-riesgo.component';
import { RisksStepsComponent } from '../risks-steps/risks-steps.component';

@Component({
  selector: 'howden-risks-list',
  templateUrl: './risks-list.component.html',
  styleUrls: ['./risks-list.component.scss']
})
export class RisksListComponent implements OnInit {
  public displayedColumns: string[] = ['riesgoId', 'nombre', 'probabilidad', 'impacto', 'select'];
  public riesgos: IRiesgoDTO[] = [];
  public dataSource = new MatTableDataSource(this.riesgos);
  public selection = new SelectionModel<any>(true, []); // Para poder ordenar las columnas
  public discardedRisks: Array<IRiesgoDTO> = [];
  public modifiedRiskIds: Array<number> = [];

  @Input() step!: IStepDTO;
  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    // Para poder ordenar las columnas, cuando la tabla está dentro de un *ngIf
    this.dataSource.sort = sort;
  }

  constructor(
    private dialog: MatDialog,
    private alertsSrv: HowdenAlertService,
    private riesgosSrv: RiesgosService,
    private dataRiskMapSrv: RisksDataMapService,
    private stepsComponent: RisksStepsComponent
  ) {
  }

  ngOnInit() {
    // Cargamos, del local storage, los datos que están alamcenados de riesgos (descartados y con datos) de esta categoría
    const dataRiskSelect = this.dataRiskMapSrv.dataRiskMap.filter(x => x.riesgos.find(r => r.categoriaId === this.step.categoriaId));

    this.discardedRisks = this.dataRiskMapSrv.listRisksDiscarded.filter(x => x.categoriaId === this.step.categoriaId);

    this.riesgosSrv.getRiesgosByCategoria(this.step.categoriaId).subscribe((data) => {
      this.riesgos = data;
      this.dataSource = new MatTableDataSource(this.riesgos);

      if (this.discardedRisks.length > 0 || dataRiskSelect.length > 0) {
        this.riesgos.forEach(riesgo => {
          if (this.discardedRisks.find(x => x.riesgoId === riesgo.riesgoId)) {
            this.selectRisk(true, riesgo, true);
          } else {
            const riesgoGuardado = dataRiskSelect.find(x => x.riesgos.find(r => r.riesgoId === riesgo.riesgoId));
            if (riesgoGuardado) {
              riesgo.probabilidad = riesgoGuardado.probabilidad;
              riesgo.impacto = riesgoGuardado.impacto;
              this.modifiedRiskIds.push(riesgo.riesgoId);
              this.compruebaPasoCompleto(riesgo);
            }
          }
        });
      }
    });
  }

  selectRisk(checkboxSelect: boolean, riesgo: IRiesgoDTO, cargaLocalStorage = false) {
    if (checkboxSelect) {
      // Riesgo descartado
      riesgo.probabilidad = null;
      riesgo.impacto = null;
      this.discardedRisks.push(riesgo);

      if (!cargaLocalStorage) {
        // Guardamos datos en el servicio
        this.dataRiskMapSrv.addDiscardedRisk(riesgo);
        this.dataRiskMapSrv.deleteDataRiskMap(riesgo.riesgoId);
      }
      this.compruebaPasoCompleto(riesgo);
    } else { // Hay que eliminar el el riesgo como descartado y poner como no modificado
      this.discardedRisks.splice(this.discardedRisks.indexOf(riesgo), 1);
      if (!cargaLocalStorage) {
        // Guardamos datos en el servicio
        this.dataRiskMapSrv.deleteDiscardedRisk(riesgo);
      }
      this.modifiedRiskIds.splice(this.modifiedRiskIds.indexOf(riesgo.riesgoId), 1);
      this.step.completo = false;
    }
  }

  updateDataRiskMapProbabilidad(riesgo: IRiesgoDTO) {
    if (typeof riesgo.probabilidad !== 'undefined' && riesgo.probabilidad !== null) {
      if (riesgo.probabilidad < 0 || riesgo.probabilidad > 5) {
        this.alertsSrv.error('Error', 'El valor de la probabilidad debe estar entre 0 y 5');
        riesgo.probabilidad = null;
      }
      if (this.hasValue(riesgo.probabilidad) && this.hasValue(riesgo.impacto)) {
        this.dataRiskMapSrv.updateDataRiskMap(riesgo);
      } else {
        this.dataRiskMapSrv.deleteDataRiskMap(riesgo.riesgoId);
      }
      this.compruebaPasoCompleto(riesgo);
    }
  }

  updateDataRiskMapImpacto(riesgo: IRiesgoDTO) {
    if (typeof riesgo.impacto !== 'undefined' && riesgo.impacto !== null) {
      if (riesgo.impacto < 0 || riesgo.impacto > 5) {
        this.alertsSrv.warning('Error', 'El valor del impacto debe estar entre 0 y 5');
        riesgo.impacto = null;
      }
      if (this.hasValue(riesgo.probabilidad) && this.hasValue(riesgo.impacto)) {
        this.dataRiskMapSrv.updateDataRiskMap(riesgo);
      } else {
        this.dataRiskMapSrv.deleteDataRiskMap(riesgo.riesgoId);
      }
      this.compruebaPasoCompleto(riesgo);
    }
  }

  compruebaPasoCompleto(riesgo: IRiesgoDTO) {
    if (!this.modifiedRiskIds.find(x => x === riesgo.riesgoId)) {
      this.modifiedRiskIds.push(riesgo.riesgoId);
    }

    let completo = true;

    for (const r of this.riesgos) { // Comprobamos si todos los riesgos están en la lista de modificados y con probabilidad e impacto
      if (!this.modifiedRiskIds.find(x => x === r.riesgoId)) {
        completo = false;
        break;
      } else if (this.discardedRisks.find(x => x === r)) { // El riesgo ha sido descartado
        // nada
      } else { // El riesgo, si tiene probabilidad (o impacto) también debe tener impacto (o probabilidad)
        if ((this.hasValue(riesgo.probabilidad) && !this.hasValue(riesgo.impacto))
          || (!this.hasValue(riesgo.probabilidad) && this.hasValue(riesgo.impacto))) {
          completo = false;
          break;
        }
      }
    }

    this.step.completo = completo;
  }

  hasValue(val: any): boolean {
    return typeof val !== 'undefined' && val !== null;
  }

  helpProbabilidad() {
    this.dialog.open(HelpProbabilidadComponent, {
      width: '80%'
    });
  }

  helpImpacto() {
    this.dialog.open(HelpImpactoComponent, {
      width: '600px'
    });
  }

  helpRiesgo(riesgo: IRiesgoDTO) {
    this.dialog.open(HelpRiesgoComponent, {
      width: '800px',
      data: { riesgo }
    });
  }

  addStepOtros() {
    this.stepsComponent.addStepOtros();
  }
}
