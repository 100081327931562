import { Component } from '@angular/core';
import { HelpImpactoElement, HelpProbabilidadElement } from 'src/app/core/models/common';
import { HelpDataService } from 'src/app/core/services/core';

@Component({
  selector: 'howden-help-risk-map',
  templateUrl: './help-risk-map.component.html',
  styleUrls: ['./help-risk-map.component.scss']
})
export class HelpRiskMapComponent {
  public dataSourceProbabilidad: HelpProbabilidadElement[] = [];
  public displayedColumnsProbabilidad: string[] = ['clasificacion', 'valor', 'frecuencia', 'ejemplo'];
  public dataSourceImpacto: HelpImpactoElement[] = [];
  public displayedColumnsImpacto: string[] = ['clasificacion', 'valor', 'cuantificacion'];

  constructor(private helpDataSrv: HelpDataService) {
    this.dataSourceProbabilidad = this.helpDataSrv.getDataHelpProbabilidad();
    this.dataSourceImpacto = this.helpDataSrv.getDataHelpImpacto();
  }
}
