/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';
import { IDataRiskMapDTO, IRiesgoDTO } from '../../models/api';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private readonly OTHER_RISKS_KEY: string = 'OtherRisks';
  private readonly DATA_RISK_MAP_KEY: string = 'DataRiskMap';
  private readonly DISCARDED_RISKS_KEY: string = 'DiscardedRisks';

  get OtherRisks(): Array<IRiesgoDTO> {
    const jsonData = localStorage.getItem(this.OTHER_RISKS_KEY);
    if (jsonData) {
      return JSON.parse(jsonData);
    }
    return [];
  }

  set OtherRisks(otherRisks: Array<IRiesgoDTO>) {
    const jsonData = JSON.stringify(otherRisks);
    localStorage.setItem(this.OTHER_RISKS_KEY, jsonData);
  }

  get DataRiskMap(): Array<IDataRiskMapDTO> {
    const jsonData = localStorage.getItem(this.DATA_RISK_MAP_KEY);
    if (jsonData) {
      return JSON.parse(jsonData);
    }
    return [];
  }

  set DataRiskMap(data: Array<IDataRiskMapDTO>) {
    const jsonData = JSON.stringify(data);
    localStorage.setItem(this.DATA_RISK_MAP_KEY, jsonData);
  }

  get DiscardedRisks(): Array<IRiesgoDTO> {
    const jsonData = localStorage.getItem(this.DISCARDED_RISKS_KEY);
    if (jsonData) {
      return JSON.parse(jsonData);
    }
    return [];
  }

  set DiscardedRisks(dicardRisks: Array<IRiesgoDTO>) {
    const jsonData = JSON.stringify(dicardRisks);
    localStorage.setItem(this.DISCARDED_RISKS_KEY, jsonData);
  }
}
