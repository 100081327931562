<!-- <div class="text-center btn-container padding-bottom-10"> -->
<div class="btn-container flex f-ai--center f-jc--center margin-bottom-10">
  <button mat-raised-button color="filler" [disabled]="otrosRiesgos.length >= 10" (click)="newRisk()">
    Nuevo Riesgo
  </button>
</div>
<div class="flex">
  <div class="f-basis--12 table-container">
    <table mat-table [dataSource]="otrosRiesgos" class="table">
      <ng-container matColumnDef="riesgoId">
        <th mat-header-cell *matHeaderCellDef>Id</th>
        <td mat-cell *matCellDef="let element">{{ element.riesgoId }}</td>
      </ng-container>
      <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef>Evento</th>
        <td mat-cell *matCellDef="let element">{{ element.nombre }}</td>
      </ng-container>

      <ng-container matColumnDef="probabilidad">
        <th mat-header-cell *matHeaderCellDef>
          Probabilidad
          <button
            mat-icon-button
            color="primary"
            aria-label="helpProbabilidad"
            title="Ayuda para los valores a asignar"
            (click)="helpProbabilidad()"
          >
            <mat-icon>help</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field appearance="standard">
            <input
              matInput
              type="number"
              step="0.5"
              min="0"
              max="5"
              [(ngModel)]="element.probabilidad"
              [ngModelOptions]="{ standalone: true }"
              (change)="updateDataOtherRiskProbabilidad(element)"
            />
          </mat-form-field>
        </td>
      </ng-container>
      <ng-container matColumnDef="impacto">
        <th mat-header-cell *matHeaderCellDef>
          Impacto
          <button
            mat-icon-button
            color="primary"
            aria-label="helpImpacto"
            title="Ayuda para los valores a asignar"
            (click)="helpImpacto()"
          >
            <mat-icon>help</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field appearance="standard">
            <input
              matInput
              type="number"
              step="0.5"
              min="0"
              max="5"
              [(ngModel)]="element.impacto"
              [ngModelOptions]="{ standalone: true }"
              (change)="updateDataOtherRiskImpacto(element)"
            />
          </mat-form-field>
        </td>
      </ng-container>
      <ng-container matColumnDef="editar">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button color="primary" aria-label="editar" (click)="editRisk(element)">
            <mat-icon>create</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="borrar">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button color="primary" aria-label="borrar" (click)="deleteRisk(element.riesgoId)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
