import { Component } from '@angular/core';
import { HelpProbabilidadElement } from 'src/app/core/models/common';
import { HelpDataService } from 'src/app/core/services/core';

@Component({
  selector: 'howden-help-probabilidad',
  templateUrl: './help-probabilidad.component.html',
  styleUrls: ['./help-probabilidad.component.scss']
})
export class HelpProbabilidadComponent {
  public dataSource: HelpProbabilidadElement[] = [];
  public displayedColumns: string[] = ['clasificacion', 'valor', 'frecuencia', 'ejemplo'];

  constructor(private helpDataSrv: HelpDataService) {
    this.dataSource = this.helpDataSrv.getDataHelpProbabilidad();
  }
}
