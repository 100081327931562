import { HowdenApiEnviroment, IHowdenApiInfo } from '@howdeniberia/core-front';
import { APP_CONSTANTS } from 'src/app/core/constants/app-constants';

export const ApiConfigurations: IHowdenApiInfo[] = [{
  name: APP_CONSTANTS.API_MAPA_RIESGOS,
  paths: {
    local: 'https://localhost:44360/api/',
    develop: 'https://how-ar-apimapariesgos-dev.azurewebsites.net/api/',
    staging: 'https://how-ar-apimapariesgos-pre.azurewebsites.net/api/',
    production: 'https://how-ar-apimapariesgos-pro.azurewebsites.net/api/',
    devHowdenArtai: 'https://how-ar-apimapariesgos-dev.azurewebsites.net/api/',
    preHowdenArtai: 'https://how-ar-apimapariesgos-pre.azurewebsites.net/api/',
    proHowdenArtai: 'https://how-ar-apimapariesgos-pro.azurewebsites.net/api/'
  },
  useEnviroment: HowdenApiEnviroment.ENVIROMENT_CHOOSE,
  needsToken: true
}] as IHowdenApiInfo[];
