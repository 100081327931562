import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthGuard,
  GENERAL_CONSTANTS,
  HowdenWithoutMenuLayoutComponent,
  NOT_AUTHORIZED_ROUTING,
  NOT_FOUND_ROUTING,
  NotAuthorizedComponent
} from '@howdeniberia/core-front';
import { APP_CONSTANTS } from './core/constants';
import { CookieDeleteComponent } from './features/cookie-delete/cookie-delete.component';
import { HomeComponent } from './features/home/home.component';
import { RisksEvaluationComponent } from './features/risks-evaluation/risks-evaluation.component';

export const AppRoutes = [
  {
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    path: '',
    component: HowdenWithoutMenuLayoutComponent,
    data: {
      isLayout: true,
      roles: [...APP_CONSTANTS.ROLES]
    },
    children: [
      { path: '', pathMatch: 'full', redirectTo: '/home' },
      {
        path: 'home',
        component: HomeComponent,
        data: { title: 'Carrusel' }
      },
      {
        path: 'cookie-delete',
        component: CookieDeleteComponent,
        data: { title: 'Borrado de cookies' }
      },
      {
        path: 'risks-evaluation',
        component: RisksEvaluationComponent,
        data: { title: 'Evaluación de Riesgos' }
      }
    ]
  },
  {
    path: GENERAL_CONSTANTS.NotAuthorizedUrl,
    title: $localize`:@@app.route.notauthorized: No autorizado`,
    component: NotAuthorizedComponent
  },
  NOT_AUTHORIZED_ROUTING,
  NOT_FOUND_ROUTING,
  { path: '**', redirectTo: '404' }
] as Routes;

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
