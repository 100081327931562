import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ICategoriaDTO, IStepDTO } from 'src/app/core/models/api';
import { CategoriasService } from 'src/app/core/services/api';
import { RisksDataMapService } from 'src/app/core/services/core';

@Component({
  selector: 'howden-risks-steps',
  templateUrl: './risks-steps.component.html',
  styleUrls: ['./risks-steps.component.scss']
})
export class RisksStepsComponent implements OnInit {
  public categorias!: Observable<ICategoriaDTO[]>;
  public steps: IStepDTO[] = [];
  public idPasoActual = 0;

  constructor(
    private categoriasSrv: CategoriasService,
    private dataRiskMapSrv: RisksDataMapService
  ) {
  }

  ngOnInit() {
    this.categoriasSrv.getCategorias().subscribe((data) => {
      data.sort(categ => categ.categoriaId).forEach(categ => {
        this.steps.push({
          categoriaId: categ.categoriaId,
          descripcion: categ.descripcion,
          completo: false,
          primero: false,
          ultimo: false,
          otros: false
        });
      });

      // Marcamos el primer y último paso
      this.steps[0].primero = true;
      this.steps[this.steps.length - 1].ultimo = true;

      // Comprabamos si tenemos que incluir el paso de otros riesgos
      if (this.dataRiskMapSrv.hasDataOtherRisks() === true) {
        this.addStepOtros();
      }
    });
  }

  compruebaFlujoCompleto(): boolean {
    if (this.steps.length > 0) {
      if (this.steps.filter(x => x.completo).length === this.steps.filter(x => !x.otros).length) {
        return true;
      }
    }
    return false;
  }

  addStepOtros() {
    const lastStep = this.steps[this.steps.length - 1];
    lastStep.ultimo = false;
    this.steps.push({
      categoriaId: 100,
      descripcion: 'Otros',
      completo: false,
      primero: false,
      ultimo: true,
      otros: true
    });
  }
}
