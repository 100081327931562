import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from '../../constants';
import { ICategoriaDTO } from '../../models/api';

@Injectable({
  providedIn: 'root'
})
export class CategoriasService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  public getCategorias(): Observable<ICategoriaDTO[]> {
    return this.httpClient.get<ICategoriaDTO[]>(APP_CONSTANTS.API_MAPA_RIESGOS, `/categorias`);
  }
}
