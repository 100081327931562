import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IDataRiskMapDTO } from 'src/app/core/models/api';
import { RisksDataMapService } from 'src/app/core/services/core';

@Component({
  selector: 'howden-risks-selection',
  templateUrl: './risks-selection.component.html',
  styleUrls: ['./risks-selection.component.scss']
})
export class RisksSelectionComponent implements OnInit {
  public subscription: any;

  public displayedColumns: string[] = ['riesgoId', 'categoriaDesc', 'nombre', 'probabilidad', 'impacto', 'orden'];
  public riesgosSelec: Array<any> = [];
  public dataSource = new MatTableDataSource(this.riesgosSelec);
  public selection = new SelectionModel<any>(true, []); // Para poder ordenar las columnas

  @ViewChild(MatSort, { static: true }) sort!: MatSort; // Para poder ordenar las columnas

  constructor(private dataRiskMapSrv: RisksDataMapService) {
  }

  ngOnInit() {
    this.subscription = this.dataRiskMapSrv.getDataRiskMapEmitter().subscribe((data: Array<IDataRiskMapDTO>) => {
      this.updateRiesgosSeleccionados(data);
    });
  }

  updateRiesgosSeleccionados(dataRiskMap: Array<IDataRiskMapDTO>) {
    this.riesgosSelec = [];

    dataRiskMap.forEach((x) => {
      x.riesgos.forEach((r) => {
        this.riesgosSelec.push({
          riesgoId: r.riesgoId,
          nombre: r.nombre,
          categoriaDesc: r.categoriaDesc,
          probabilidad: x.probabilidad,
          impacto: x.impacto,
          orden: x.orden,
          color: x.color
        });
      });
    });

    this.dataSource = new MatTableDataSource(this.riesgosSelec);
    this.dataSource.sort = this.sort;
  }
}
