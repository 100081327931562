import { EventEmitter, Injectable } from '@angular/core';
import { IRiesgoDTO } from '../../models/api';
import { RiesgosService } from '../api';
import { LocalStorageService } from '../ui';

@Injectable({
  providedIn: 'root'
})
export class OtherRisksDataService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public OtrosRiesgos_Categoria_Id = 0;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public readonly OtrosRiesgos_Categoria_Desc: string = 'Otros Riesgos';

  public listOtherRisks: Array<IRiesgoDTO> = [];
  public dataChange: EventEmitter<Array<IRiesgoDTO>> = new EventEmitter();

  constructor(
    private localStorageSrv: LocalStorageService,
    private riesgosSrv: RiesgosService
  ) {
    // Inicializamos el id de los otros riesgos
    this.riesgosSrv.getMaxId().subscribe((maxId: number) => {
      this.OtrosRiesgos_Categoria_Id = maxId + 1;
    });

    // Inicializamos con lo que hay en el LocalStorage
    this.listOtherRisks = this.localStorageSrv.OtherRisks;
  }

  getListOtherRisksEmitter() {
    return this.dataChange;
  }

  addNewOtherRisk(riesgo: IRiesgoDTO) {
    let riesgoId = this.OtrosRiesgos_Categoria_Id;
    if (this.listOtherRisks.length > 0) {
      // Recuperamos el máximo valor
      // eslint-disable-next-line prefer-spread
      riesgoId = Math.max.apply(Math, this.listOtherRisks.map(function (r) { return r?.riesgoId ?? 0; }));
      riesgoId++;
    }

    riesgo.riesgoId = riesgoId;
    riesgo.categoriaId = this.OtrosRiesgos_Categoria_Id;
    riesgo.categoriaDesc = this.OtrosRiesgos_Categoria_Desc;

    this.listOtherRisks.push(riesgo);
    this.localStorageSrv.OtherRisks = this.listOtherRisks;
    this.dataChange.emit(this.listOtherRisks);
  }

  updateOtherRisk(riesgo: IRiesgoDTO) {
    const riesgoStore: IRiesgoDTO | undefined = this.listOtherRisks.find(x => x.riesgoId === riesgo.riesgoId);

    if (riesgoStore) {
      const indexData = this.listOtherRisks.indexOf(riesgoStore);

      this.listOtherRisks[indexData] = riesgo;
      this.localStorageSrv.OtherRisks = this.listOtherRisks;
      this.dataChange.emit(this.listOtherRisks);
    }
  }

  deleteOtherRisk(riesgoId: number) {
    const riesgoStore: IRiesgoDTO | undefined = this.listOtherRisks.find(x => x.riesgoId === riesgoId);
    if (riesgoStore) {
      this.listOtherRisks.splice(this.listOtherRisks.indexOf(riesgoStore), 1);
      this.localStorageSrv.OtherRisks = this.listOtherRisks;
      this.dataChange.emit(this.listOtherRisks);
    }
  }
}
