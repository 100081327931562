import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from '../../constants';
import { ILocalidadDTO } from '../../models/api';

@Injectable({
  providedIn: 'root'
})
export class LocalidadesService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  public getLocalidades(): Observable<ILocalidadDTO[]> {
    return this.httpClient.get<ILocalidadDTO[]>(APP_CONSTANTS.API_MAPA_RIESGOS, `/localidades`);
  }
}
