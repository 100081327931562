import { CommonModule, registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import {
  HowdenAlertsModule,
  HowdenCoreModule,
  HowdenErrorPagesModule,
  HowdenPageTitleModule,
  HowdenSharedModule,
  HowdenUserService,
  IHowdenEnvironment,
  IPackageJsonWrapper,
  NotificationsService,
  getPaginatorIntl
} from '@howdeniberia/core-front';
import 'hammerjs';
import { CookieService } from 'ngx-cookie-service';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxHmCarouselModule } from 'ngx-hm-carousel';
import { ApiConfigurations } from 'src/environments/api-configurations';
import { environment } from 'src/environments/environment';
import packageJson from '../../package.json';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ArraySortPipe } from './core/pipes/arraysort.pipe';
import { ConfirmationDialogSrv, IConfirmationDialogSrv } from './core/services/ui';
import { CookieDeleteComponent } from './features/cookie-delete/cookie-delete.component';
import { DownloadReportComponent } from './features/download-report/download-report.component';
import { EditRiskComponent } from './features/edit-risk/edit-risk.component';
import { HelpImpactoComponent } from './features/help-impacto/help-impacto.component';
import { HelpProbabilidadComponent } from './features/help-probabilidad/help-probabilidad.component';
import { HelpRiesgoComponent } from './features/help-riesgo/help-riesgo.component';
import { HelpRiskMapComponent } from './features/help-risk-map/help-risk-map.component';
import { HomeComponent } from './features/home/home.component';
import { RisksEvaluationComponent } from './features/risks-evaluation/risks-evaluation.component';
import { RisksListComponent } from './features/risks-list/risks-list.component';
import { RisksMapComponent } from './features/risks-map/risks-map.component';
import { RisksOthersComponent } from './features/risks-others/risks-others.component';
import { RisksSelectionComponent } from './features/risks-selection/risks-selection.component';
import { RisksStepsComponent } from './features/risks-steps/risks-steps.component';

registerLocaleData(localeEs, 'es');

function getLocaleId(userSrv: HowdenUserService) {
  return userSrv?.userData?.selectedLanguage ?? 'es';
}

@NgModule({
  declarations: [
    ArraySortPipe,
    AppComponent,
    CookieDeleteComponent,
    DownloadReportComponent,
    EditRiskComponent,
    HelpImpactoComponent,
    HelpProbabilidadComponent,
    HelpRiesgoComponent,
    HelpRiskMapComponent,
    HomeComponent,
    RisksEvaluationComponent,
    RisksListComponent,
    RisksMapComponent,
    RisksOthersComponent,
    RisksSelectionComponent,
    RisksStepsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxEchartsModule.forRoot({ echarts: () => import('echarts') }),
    NgxHmCarouselModule,
    AppRoutingModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatSidenavModule,
    MatStepperModule,
    MatTabsModule,
    MatTableModule,
    MatToolbarModule,
    HowdenPageTitleModule,
    HowdenErrorPagesModule,
    HowdenAlertsModule,
    HowdenSharedModule,
    HowdenCoreModule.forRoot(environment as IHowdenEnvironment, packageJson as IPackageJsonWrapper, ApiConfigurations)
  ],
  providers: [
    CookieService,
    NotificationsService,
    { provide: IConfirmationDialogSrv, useClass: ConfirmationDialogSrv },
    { provide: LOCALE_ID, useFactory: getLocaleId, deps: [HowdenUserService] },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: { formFieldAppearance: 'legacy' } },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { disableClose: true, autoFocus: true, restoreFocus: true } },
    { provide: MatPaginatorIntl, useValue: getPaginatorIntl() }
  ],
  entryComponents: [
    HelpProbabilidadComponent,
    HelpImpactoComponent,
    HelpRiesgoComponent,
    HelpRiskMapComponent,
    DownloadReportComponent,
    EditRiskComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
