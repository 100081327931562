<form [formGroup]="formRisk" (ngSubmit)="saveRisk()">
  <h1 mat-dialog-title>Editar Riesgo</h1>
  <div mat-dialog-content>
    <mat-form-field>
      <mat-label>Descripción</mat-label>
      <input matInput placeholder="Descripción" autofocus formControlName="descripcion" />
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="btn-container flex f-ai--center f-jc--center">
    <button mat-raised-button type="button" (click)="cancel()" color="accent">Cancelar</button>
    <button mat-raised-button [disabled]="formRisk.invalid" color="accent">Guardar</button>
  </div>
</form>
