<div class="carousel flex f-fd--column">
  <ngx-hm-carousel
    [(ngModel)]="currentIndex"
    (ngModelChange)="indexChanged($event)"
    [autoplay-speed]="speed"
    [data]="avatars"
    [autoplay]="autoplay"
    [infinite]="infinite"
    [between-delay]="2000"
    [aniTime]="200"
    class="carousel"
  >
    <section ngx-hm-carousel-container class="content">
      <article
        class="item cursor-pointer"
        ngx-hm-carousel-item
        *ngFor="let avatar of avatars; let i = index"
        [ngClass]="{'visible': currentIndex===i}"
      >
        <div class="carousel-container">
          <div class="flex f-ai--center f-jc--center">
            <div class="flex f-ai--center f-jc--center f-basis--6">
              <span class="material-symbols-outlined">{{ avatar.urlImage }}</span>
            </div>
            <div class="flex f-ai--center f-jc--center f-basis--6 carousel-message">
              {{ avatar.message }}
            </div>
          </div>
        </div>
      </article>
      <ng-template #infiniteContainer></ng-template>
    </section>

    <ng-template #carouselContent let-avatar let-i="index">
      <article class="item cursor-pointer" [ngClass]="{'visible': currentIndex===i}"></article>
    </ng-template>

    <ng-template #carouselPrev>
      <div class="click-area">
        <i class="material-symbols-outlined">keyboard_arrow_left</i>
      </div>
    </ng-template>

    <ng-template #carouselNext>
      <div class="click-area">
        <i class="material-symbols-outlined">keyboard_arrow_right</i>
      </div>
    </ng-template>

    <ng-template #carouselDot let-model>
      <div class="ball bg-accent" [class.visible]="model.index === model.currentIndex"></div>
    </ng-template>
  </ngx-hm-carousel>

  <div class="flex f-fd--column f-ai--center margin-bottom-25 btn-container" *ngIf="!mostrarAvisoCookies">
      <button mat-raised-button color="filler" (click)="goToRiskMap()">
          Realizar Autoevaluación de Riesgos
      </button>
  </div>

  <mat-toolbar class="cookies flex" *ngIf="mostrarAvisoCookies">
    <div class="f-basis--10 flex f-gap--10px f-jc--center">
      <span>Utilizamos cookies para mejorar tu navegación, ¿De acuerdo? &nbsp;
      <a href="https://www.howdeniberia.com/politica-de-cookies/" target="_blank">Leer más </a></span>&nbsp;
      <button mat-raised-button type="button" (click)="aceptarCookies()">Aceptar</button>
    </div>
    <div class="f-basis--2">
      <button mat-icon-button (click)="borrarCookies()">
        <mat-icon>delete</mat-icon> Salir y borrar las cookies
      </button>
    </div>
  </mat-toolbar>
</div>
