import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from '../../constants';
import { IRiesgoDTO } from '../../models/api';

@Injectable({
  providedIn: 'root'
})
export class RiesgosService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  public getRiesgosByCategoria(categoriaId: number): Observable<IRiesgoDTO[]> {
    return this.httpClient.get<IRiesgoDTO[]>(APP_CONSTANTS.API_MAPA_RIESGOS, `/riesgos/byCategoria/${categoriaId}`);
  }

  public getMaxId(): Observable<number> {
    return this.httpClient.get<number>(APP_CONSTANTS.API_MAPA_RIESGOS, '/riesgos/maxId');
  }
}
