<h1 mat-dialog-title>Probabilidades</h1>
<div mat-dialog-content class="table-container">
  <table mat-table [dataSource]="dataSource" class="table">
    <ng-container matColumnDef="clasificacion">
      <th mat-header-cell *matHeaderCellDef>Clasificación</th>
      <td mat-cell *matCellDef="let element">{{ element.clasificacion }}</td>
    </ng-container>
    <ng-container matColumnDef="valor">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="text-center">{{ element.valor }}</td>
    </ng-container>
    <ng-container matColumnDef="frecuencia">
      <th mat-header-cell *matHeaderCellDef>Frecuencia</th>
      <td mat-cell *matCellDef="let element">{{ element.frecuencia }}</td>
    </ng-container>
    <ng-container matColumnDef="ejemplo">
      <th mat-header-cell *matHeaderCellDef>Ejemplo</th>
      <td mat-cell *matCellDef="let element">{{ element.ejemplo }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<div mat-dialog-actions class="btn-container flex f-ai--center f-jc--center">
  <button mat-button mat-dialog-close>Cerrar</button>
</div>
