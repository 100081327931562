import { Injectable } from '@angular/core';
import { HelpImpactoElement, HelpProbabilidadElement } from '../../models/common';

@Injectable({
  providedIn: 'root'
})
export class HelpDataService {
  getDataHelpProbabilidad(): HelpProbabilidadElement[] {
    const data: HelpProbabilidadElement[] = [
      {
        clasificacion: 'Muy Alta',
        valor: '5',
        frecuencia: 'Ocurre una vez al año',
        ejemplo: 'Temporal por paso de TCA, DANA, impacto de vehículos o fallos en la calidad de un producto o servicio'
      },
      {
        clasificacion: 'Alta',
        valor: '4',
        frecuencia: 'Ocurre una vez cada tres años',
        ejemplo: 'Infección de un PC con un virus o similar, robo en una instalación, pérdida de un móvil o multa de tráfico'
      },
      {
        clasificacion: 'Media',
        valor: '3',
        frecuencia: 'Ocurre una vez cada 10 años',
        ejemplo: 'Bloqueo de carreteras principales por acumulación de nieve o huelga sectorial'
      },
      {
        clasificacion: 'Baja',
        valor: '2',
        frecuencia: 'Ocurre una vez cada 50 años',
        ejemplo: 'Terremoto de fuerza 6 en territorio español'
      },
      {
        clasificacion: 'Muy Baja',
        valor: '1',
        frecuencia: 'Ocurre una vez cada 100 años',
        ejemplo: 'Pandemia a nivel mundial'
      }
    ];
    return data;
  }

  getDataHelpImpacto(): HelpImpactoElement[] {
    const data: HelpImpactoElement[] = [
      { clasificacion: 'Muy Alta', valor: '5', cuantificacion: 'Pérdidas superiores al 20% del EBITDA' },
      { clasificacion: 'Alta', valor: '4', cuantificacion: 'Pérdidas superiores al 5% del EBITDA' },
      { clasificacion: 'Media', valor: '3', cuantificacion: 'Pérdidas superiores al 1% del EBITDA' },
      { clasificacion: 'Baja', valor: '2', cuantificacion: 'Pérdidas superiores al 0,1% del EBITDA' },
      { clasificacion: 'Muy Baja', valor: '1', cuantificacion: 'Pérdidas inferiores al 0,05% del EBITDA' }
    ];

    return data;
  }
}
