import { Component } from '@angular/core';
import { HelpImpactoElement } from 'src/app/core/models/common';
import { HelpDataService } from 'src/app/core/services/core';

@Component({
  selector: 'howden-help-impacto',
  templateUrl: './help-impacto.component.html',
  styleUrls: ['./help-impacto.component.scss']
})
export class HelpImpactoComponent {
  public dataSource: HelpImpactoElement[] = [];
  public displayedColumns: string[] = ['clasificacion', 'valor', 'cuantificacion'];

  constructor(private helpDataSrv: HelpDataService) {
    this.dataSource = this.helpDataSrv.getDataHelpImpacto();
  }
}
