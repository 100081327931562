import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { HowdenAlertService } from '@howdeniberia/core-front';
import html2canvas from 'html2canvas';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { IInformeDTO, ILocalidadDTO } from 'src/app/core/models/api';
import { InformeService, LocalidadesService } from 'src/app/core/services/api';
import { RisksDataMapService } from 'src/app/core/services/core';
import { FileUtils } from 'src/app/shared/utils';

@Component({
  selector: 'howden-download-report',
  templateUrl: './download-report.component.html',
  styleUrls: ['./download-report.component.scss']
})
export class DownloadReportComponent implements OnInit {
  public downloadReportForm!: FormGroup;

  public localidades: ILocalidadDTO[] = [];
  public localidadesFiltradas!: Observable<ILocalidadDTO[]>;

  constructor(
    public dialogRef: MatDialogRef<DownloadReportComponent>,
    private alertsSrv: HowdenAlertService,
    private localidadesSrv: LocalidadesService,
    private dataRiskMapSrv: RisksDataMapService,
    private informeSrv: InformeService
  ) {
    this.downloadReportForm = new FormGroup({
      razonSocial: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
      cif: new FormControl(null, [Validators.required, Validators.maxLength(15)]),
      email: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
      telefono: new FormControl(null, [Validators.required, Validators.maxLength(15)]),
      localidad: new FormControl(null, Validators.required),
      politicaPrivacidad: new FormControl(false, Validators.requiredTrue)
    });
  }

  getControl(controlName: string): AbstractControl<any, any> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.downloadReportForm.get(controlName)!;
  }

  ngOnInit() {
    const element = document.getElementById('divGraficoMapa');
    if (element) {
      // Generamos la imagen del mapa y guardamos el código de la imagen en base64 en un input hidden
      html2canvas(element, { logging: false }).then(function (canvas: any) {
        const obj = document.getElementById('imagenMapaBase64');
        if (obj) {
          obj.innerHTML = canvas.toDataURL('image/png');
        }
      });
    }

    this.localidadesSrv.getLocalidades().subscribe((locali: ILocalidadDTO[]) => {
      this.localidades = locali;
      this.localidadesFiltradas = this.getControl('localidad').valueChanges.pipe(
        startWith(''),
        map((value: any) => this._filtrarLocalidad(value))
      );
    });
  }

  private _filtrarLocalidad(value: string): ILocalidadDTO[] {
    const filterValue = value.toLowerCase();

    if (this.localidades) {
      return this.localidades.filter((option) => option.descripcion && option.descripcion.toLowerCase().indexOf(filterValue) > -1);
    }
    return [];
  }

  createAndDownloadReport() {
    const informeDTO = {
      razonSocial: this.getControl('razonSocial').value,
      cif: this.getControl('cif').value,
      email: this.getControl('email').value,
      telefono: this.getControl('telefono').value,
      localidad: this.getControl('localidad').value,
      imagenMapaBase64: document.getElementById('imagenMapaBase64')?.innerText ?? '', // Cargamos imagen del hidden
      datosMapa: this.dataRiskMapSrv.dataRiskMap
    } as IInformeDTO;

    this.informeSrv.generarInforme(informeDTO).subscribe((result) => {
      if (!result) {
        this.alertsSrv.error('Error', 'Se ha producido un error al generar el informe');
      } else {
        FileUtils.downloadRawFile('MapaRiesgos.pdf', result);
      }
    });
  }
}
