<h1 mat-dialog-title>Ejemplo</h1>
<div mat-dialog-content [ngClass]="{ noData: hayEjemplo === false }">
  <div *ngIf="hayEjemplo; else noData">
    {{ ejemplo }}
  </div>
  <ng-template #noData>No se ha facilitado ningún ejemplo</ng-template>
</div>
<div mat-dialog-actions class="btn-container flex f-ai--center f-jc--center">
  <button mat-button mat-dialog-close>Cerrar</button>
</div>
