import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogResultTypes, HowdenAlertService, IDialogResult } from '@howdeniberia/core-front';
import { tap } from 'rxjs';
import { IRiesgoDTO } from 'src/app/core/models/api';
import { OtherRisksDataService, RisksDataMapService } from 'src/app/core/services/core';
import { IConfirmationDialogSrv } from 'src/app/core/services/ui';
import { EditRiskComponent } from '../edit-risk/edit-risk.component';
import { HelpImpactoComponent } from '../help-impacto/help-impacto.component';
import { HelpProbabilidadComponent } from '../help-probabilidad/help-probabilidad.component';

@Component({
  selector: 'howden-risks-others',
  templateUrl: './risks-others.component.html',
  styleUrls: ['./risks-others.component.scss']
})
export class RisksOthersComponent implements OnInit {
  public displayedColumns: string[] = ['riesgoId', 'nombre', 'probabilidad', 'impacto', 'editar', 'borrar'];
  public otrosRiesgos: Array<IRiesgoDTO> = [];
  public subscription: any;

  constructor(
    private dialog: MatDialog,
    private alertsSrv: HowdenAlertService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private otherRisksDataSrv: OtherRisksDataService,
    private dataRiskMapSrv: RisksDataMapService
  ) {
  }

  ngOnInit() {
    // Si hay información del mapa de riesgos cargada en el local storage, cargamos los datos de otros riesgos
    const dataOtherRisk = this.dataRiskMapSrv.dataRiskMap.filter(x =>
      x.riesgos.find(r => r.categoriaId === this.otherRisksDataSrv.OtrosRiesgos_Categoria_Id
      ));
    if (dataOtherRisk && dataOtherRisk.length > 0) {
      this.otherRisksDataSrv.listOtherRisks.forEach(riesgo => {
        const riesgoGuardado = dataOtherRisk.find(x => x.riesgos.find(r => r.riesgoId === riesgo.riesgoId));
        if (riesgoGuardado === null) {
          riesgo.probabilidad = null;
          riesgo.impacto = null;
        }
        this.otrosRiesgos.push(riesgo);
      });
    } else { // Cargamos los otros riesgos que hay que el local storage
      this.otherRisksDataSrv.listOtherRisks.forEach(r => {
        // No recuperamos la probabilidad ni el impacto
        r.probabilidad = null;
        r.impacto = null;
        this.otrosRiesgos.push(r);
      });
    }

    // Nos suscribimos a los cambios que se realizan en el servicio que gestiona los otros riesgos
    this.subscription = this.otherRisksDataSrv.getListOtherRisksEmitter().subscribe((data: Array<IRiesgoDTO>) => {
      this.loadOtherRisks(data);
    });
  }

  loadOtherRisks(risks: Array<IRiesgoDTO>) {
    this.otrosRiesgos = [];
    risks.forEach((r) => {
      this.otrosRiesgos.push(r);
    });
  }

  newRisk() {
    this.dialog.open(EditRiskComponent, {
      width: '400px'
    });
  }

  editRisk(riesgo: IRiesgoDTO) {
    this.dialog.open(EditRiskComponent, {
      width: '400px',
      data: { riesgo }
    });
  }

  deleteRisk(riesgoId: number) {
    const title = 'Confirmación borrado';
    const question = '¿Confirma que desea borrar el riesgo?';

    this.confirmDialogSrv.openDanger(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          this.otherRisksDataSrv.deleteOtherRisk(riesgoId);
          this.dataRiskMapSrv.deleteDataRiskMap(riesgoId);
        }
      })
    ).subscribe();
  }

  updateDataOtherRiskProbabilidad(riesgo: IRiesgoDTO) {
    if (riesgo.probabilidad) {
      if (riesgo.probabilidad < 0 || riesgo.probabilidad > 5) {
        this.alertsSrv.warning('Error', 'El valor de la probabilidad debe estar entre 0 y 5');
        riesgo.probabilidad = null;
      }
      this.otherRisksDataSrv.updateOtherRisk(riesgo);
      if (riesgo.probabilidad && riesgo.impacto) {
        this.dataRiskMapSrv.updateDataRiskMap(riesgo);
      } else {
        this.dataRiskMapSrv.deleteDataRiskMap(riesgo.riesgoId);
      }
    }
  }

  updateDataOtherRiskImpacto(riesgo: IRiesgoDTO) {
    if (riesgo.impacto) {
      if (riesgo.impacto < 0 || riesgo.impacto > 5) {
        this.alertsSrv.warning('Error', 'El valor del impacto debe estar entre 0 y 5');
        riesgo.impacto = null;
      }
      this.otherRisksDataSrv.updateOtherRisk(riesgo);
      if (riesgo.probabilidad && riesgo.impacto) {
        this.dataRiskMapSrv.updateDataRiskMap(riesgo);
      } else {
        this.dataRiskMapSrv.deleteDataRiskMap(riesgo.riesgoId);
      }
    }
  }

  helpProbabilidad() {
    this.dialog.open(HelpProbabilidadComponent, {
      width: '70%'
    });
  }

  helpImpacto() {
    this.dialog.open(HelpImpactoComponent, {
      width: '700px'
    });
  }
}
