import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'howden-cookie-delete',
  templateUrl: './cookie-delete.component.html',
  styleUrls: ['./cookie-delete.component.scss']
})
export class CookieDeleteComponent {
  constructor(private router: Router) {
  }

  volverAlMapa() {
    this.router.navigate(['/home']);
  }
}
