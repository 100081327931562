import { Component, Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { IRiesgoDTO } from 'src/app/core/models/api';
import { OtherRisksDataService } from 'src/app/core/services/core';

@Component({
  selector: 'howden-edit-risk',
  templateUrl: './edit-risk.component.html',
  styleUrls: ['./edit-risk.component.scss']
})
export class EditRiskComponent {
  public formRisk!: FormGroup;
  private riesgo!: IRiesgoDTO;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditRiskComponent>,
    public otherRisksDataSrv: OtherRisksDataService
  ) {
    this.formRisk = new FormGroup({
      descripcion: new FormControl(null, [Validators.required, Validators.maxLength(200)])
    });

    if (data) {
      if (data.riesgo) {
        this.riesgo = data.riesgo;
        this.getControl('descripcion').setValue(data.riesgo.nombre);
      }
    }
  }

  getControl(controlName: string): AbstractControl<any, any> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.formRisk.get(controlName)!;
  }

  saveRisk() {
    if (!this.riesgo || !this.riesgo.riesgoId) {
      this.riesgo = {} as IRiesgoDTO;
      this.riesgo.nombre = this.getControl('descripcion').value;
      this.otherRisksDataSrv.addNewOtherRisk(this.riesgo);
    } else {
      this.riesgo.nombre = this.getControl('descripcion').value;
      this.otherRisksDataSrv.updateOtherRisk(this.riesgo);
    }
    this.closeDialog(DialogResultTypes.OK);
  }

  cancel() {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
