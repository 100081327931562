import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RisksDataMapService } from 'src/app/core/services/core';
import { LocalStorageService } from 'src/app/core/services/ui';
import { DownloadReportComponent } from '../download-report/download-report.component';
import { HelpRiskMapComponent } from '../help-risk-map/help-risk-map.component';
import { RisksStepsComponent } from '../risks-steps/risks-steps.component';

@Component({
  selector: 'howden-risks-evaluation',
  templateUrl: './risks-evaluation.component.html',
  styleUrls: ['./risks-evaluation.component.scss']
})
export class RisksEvaluationComponent implements OnInit {
  public pasosCompletos!: boolean;

  @ViewChild(RisksStepsComponent, { static: true }) childSteps!: RisksStepsComponent;

  constructor(
    private dialog: MatDialog,
    private localStorageSrv: LocalStorageService,
    private dataRiskMapSrv: RisksDataMapService
  ) {
  }

  ngOnInit() {
    if (this.localStorageSrv.DataRiskMap.length > 0 || this.localStorageSrv.DiscardedRisks.length > 0) {
      if (confirm('Hay datos almacenados de un mapa realizado anteriormente\r\n ¿Desea recuperarlos?')) {
        this.dataRiskMapSrv.loadDataLocalStorage();
      }
    }
    // Abrimos la ayuda del mapa
    this.helpRiskMap();
  }

  helpRiskMap() {
    this.dialog.open(HelpRiskMapComponent, {
      height: '95%',
      width: '95%',
      maxHeight: '100vw !important',
      maxWidth: '100vw !important'
    });
  }

  downloadRiskReport() {
    this.dialog.open(DownloadReportComponent, {
      width: '1000px'
    });
  }
}
