import { EnviromentZone, HowdenLoginBehaviour, HowdenLoginKind, IHowdenEnvironment, LogLevel, TENANT_TYPES } from '@howdeniberia/core-front';

export const environment = {
  zone: EnviromentZone.PREHOWDENARTAI,
  appIdSecurity: 12,
  emailError: 'desarrollo@howdeniberia.onmicrosoft.com',
  loginBehaviour: HowdenLoginBehaviour.REDIRECT,
  loginKind: HowdenLoginKind.EXTERNAL,
  howdenLoginUrl: 'https://login.app-staging.howdenartai.com',
  externalAppTitle: 'Mapa de Riesgos',
  logLevel: LogLevel.INFO,
  fixedTenant: TENANT_TYPES.artai
} as IHowdenEnvironment;
