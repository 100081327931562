import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'howden-help-riesgo',
  templateUrl: './help-riesgo.component.html',
  styleUrls: ['./help-riesgo.component.scss']
})
export class HelpRiesgoComponent {
  public ejemplo = '';
  public hayEjemplo = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.ejemplo = data.riesgo.descripcion;
      if (this.ejemplo === '') {
        this.hayEjemplo = false;
      } else {
        this.hayEjemplo = true;
      }
    }
  }
}
