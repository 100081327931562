import { EventEmitter, Injectable } from '@angular/core';
import { EChartsOption } from 'echarts';
import { IDataRiskMapDTO, IRiesgoDTO, IStyleSimbolRiskMapDTO } from '../../models/api';
import { CategoriasService } from '../api';
import { LocalStorageService } from '../ui';
import { OtherRisksDataService } from './other-risks-data.service';

@Injectable({
  providedIn: 'root'
})
export class RisksDataMapService {
  public dataRiskMap: Array<IDataRiskMapDTO> = [];
  public listRisksDiscarded: Array<IRiesgoDTO> = [];
  public dataChange: EventEmitter<Array<IDataRiskMapDTO>> = new EventEmitter();
  public stilesDataRiskMapType = new Array<IStyleSimbolRiskMapDTO>();

  public numRegLine = 4;
  public listSimbols: string[] = ['circle', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow'];
  public listColors: string[] = ['#3C0F11', '#8D741E', '#173F35', '#E0EC89'];
  public simbolsStyles = [
    { simbolo: 'rect', size: 20, etiqPos: 'middle' },
    { simbolo: 'circle', size: 25, etiqPos: 'middle' },
    { simbolo: 'roundRect', size: 25, etiqPos: 'middle' },
    { simbolo: 'triangle', size: 30, etiqPos: 'top' },
    { simbolo: 'diamond', size: 30, etiqPos: 'middle' },
    { simbolo: 'pin', size: 38, etiqPos: 'middle' },
    { simbolo: 'arrow', size: 25, etiqPos: 'middle' }
  ];

  public verde = '#0DA20D';
  public amarillo = '#FFCC00';
  public naranja = '#FAAA00';
  public rojo = '#DC000F';
  public gris = '#8A8A8A';

  constructor(
    private localStorageSrv: LocalStorageService,
    private categoriasSrv: CategoriasService,
    private otherRisksDataSrv: OtherRisksDataService
  ) {
    // Cargamos estilos
    this.stilesDataRiskMapType.push({
      // Para cuando coinciden varias categorías
      categoria: 99,
      simbolo: 'rect',
      color: '#3D3D3D'
    });

    let indexSimbol = 0;
    let indexColor = 0;
    let longArray = 0;

    // Establecemos los estilos de los símbolos
    this.categoriasSrv.getCategorias().subscribe((data) => {
      // Incluimos categoría para otros riesgos
      data.push({
        categoriaId: otherRisksDataSrv.OtrosRiesgos_Categoria_Id,
        descripcion: otherRisksDataSrv.OtrosRiesgos_Categoria_Desc
      });

      data.forEach(categ => {
        this.stilesDataRiskMapType.push({
          categoria: categ.categoriaId,
          simbolo: this.listSimbols[indexSimbol],
          color: this.listColors[indexColor]
        });

        longArray++;
        if (longArray < this.listSimbols.length) { // Sólo cambiamos símbolo
          indexSimbol++;
        } else if (longArray > this.listSimbols.length * this.listColors.length) { // Reiniciamos contadores
          indexSimbol = 0;
          indexColor = 0;
          longArray = 0;
        } else {
          indexSimbol = 0;
          longArray = 0;
          indexColor++;
        }
      });
      // Por si ya hay datos cargados del loadDataLocalStorage
      this.dataChange.emit(this.dataRiskMap);
    });
  }

  public loadDataLocalStorage() {
    // Inicializamos el mapa con lo que hay en el local storage
    this.listRisksDiscarded = this.localStorageSrv.DiscardedRisks;
    this.dataRiskMap = this.localStorageSrv.DataRiskMap;
    this.dataChange.emit(this.dataRiskMap);
  }

  public getDataRiskMapEmitter() {
    return this.dataChange;
  }

  public addDataRiskMap(riesgo: IRiesgoDTO) {
    this.checkDataRiskMap(riesgo, 0, 0);
  }

  public updateDataRiskMap(riesgo: IRiesgoDTO) {
    this.checkDataRiskMap(riesgo, riesgo.probabilidad ?? 0, riesgo.impacto ?? 0);
  }

  public deleteDataRiskMap(riesgoId: number) {
    this.deleteRisk(riesgoId);
    // Guardamos cambio en el localstorage
    this.localStorageSrv.DataRiskMap = this.dataRiskMap;
    this.dataChange.emit(this.dataRiskMap);
  }

  public addDiscardedRisk(riesgo: IRiesgoDTO) {
    this.listRisksDiscarded.push(riesgo);
    this.localStorageSrv.DiscardedRisks = this.listRisksDiscarded;
  }

  public deleteDiscardedRisk(riesgo: IRiesgoDTO) {
    this.listRisksDiscarded.splice(this.listRisksDiscarded.indexOf(riesgo), 1);
    this.localStorageSrv.DiscardedRisks = this.listRisksDiscarded;
  }

  public hasDataOtherRisks(): boolean {
    const otherRiskData = this.dataRiskMap.filter(
      x => x.riesgos.find(r => r.categoriaId === this.otherRisksDataSrv.OtrosRiesgos_Categoria_Id)
    );
    return (otherRiskData !== null && otherRiskData.length > 0);
  }

  private checkDataRiskMap(riesgo: IRiesgoDTO, probabilidad: number, impacto: number) {
    const riskItem: IDataRiskMapDTO | undefined = this.dataRiskMap.find(x => x.riesgos.find(r => r.riesgoId === riesgo.riesgoId));
    const dataItem: IDataRiskMapDTO | undefined = this.dataRiskMap.find(x => x.probabilidad === probabilidad && x.impacto === impacto);

    if (!riskItem && !dataItem) {
      this.addNewRisk(riesgo, probabilidad, impacto);
    } else {
      if (riskItem) {
        this.deleteRisk(riesgo.riesgoId);
      }
      if (dataItem) {
        this.addNewRiskToData(dataItem, riesgo);
      } else {
        this.addNewRisk(riesgo, probabilidad, impacto);
      }
    }
    // Guardamos cambio en el localstorage
    this.localStorageSrv.DataRiskMap = this.dataRiskMap;
    this.dataChange.emit(this.dataRiskMap);
  }

  // Añadimos nuevo punto al gráfico
  private addNewRisk(riesgo: IRiesgoDTO, probabilidad: number, impacto: number) {
    const estilo: IStyleSimbolRiskMapDTO | undefined = this.stilesDataRiskMapType.find(x => x.categoria === riesgo.categoriaId);
    const color = this.calculaColor(probabilidad, impacto);

    let orden = probabilidad * impacto;
    let imgCirculo = 'circulo-gris.png';

    switch (color) {
      case (this.rojo):
        orden = orden + 400;
        imgCirculo = 'circulo-rojo.png';
        break;
      case (this.naranja):
        orden = orden + 300;
        imgCirculo = 'circulo-naranja.png';
        break;
      case (this.amarillo):
        orden = orden + 200;
        imgCirculo = 'circulo-amarillo.png';
        break;
      case (this.verde):
        orden = orden + 100;
        imgCirculo = 'circulo-verde.png';
        break;
    }

    this.dataRiskMap.push({
      riesgos: [riesgo],
      codigo: this.getCodigoDataRiskMap([riesgo]),
      etiqueta: this.getEtiquetaDataRiskMap([riesgo]),
      probabilidad: probabilidad,
      impacto: impacto,
      color: color,
      imgCirculo: imgCirculo,
      orden: orden,
      simbolo: estilo?.simbolo ?? '',
      simbAlto: this.simbolsStyles.find(x => x.simbolo === estilo?.simbolo)?.size ?? 0,
      simbAncho: this.simbolsStyles.find(x => x.simbolo === estilo?.simbolo)?.size ?? 0,
      simbPosEtiq: this.simbolsStyles.find(x => x.simbolo === estilo?.simbolo)?.etiqPos ?? '',
      simbColor: estilo?.color ?? ''
    });
  }

  // Añadir nuevo riesgo a un punto del gráfico
  private addNewRiskToData(dataItem: IDataRiskMapDTO, riesgo: IRiesgoDTO) {
    const indexData = this.dataRiskMap.indexOf(dataItem);

    // Añadimos riesgo a los datos de probabilidad e impacto
    dataItem.riesgos.push(riesgo);
    dataItem.codigo = this.getCodigoDataRiskMap(dataItem.riesgos);
    dataItem.etiqueta = this.getEtiquetaDataRiskMap(dataItem.riesgos);

    const estilo: IStyleSimbolRiskMapDTO | undefined = this.stilesDataRiskMapType.find(x => x.categoria === 99);

    if (estilo) {
      dataItem.simbolo = estilo?.simbolo;
      dataItem.simbAlto = this.calculaAltoMultiPunto(dataItem.riesgos.length, estilo.simbolo);
      dataItem.simbAncho = this.calculaAnchoMultiPunto(dataItem.riesgos.length, estilo.simbolo);
      dataItem.simbPosEtiq = this.simbolsStyles?.find(x => x.simbolo === estilo.simbolo)?.etiqPos ?? '';
      dataItem.simbColor = estilo.color;
    }

    this.dataRiskMap[indexData] = dataItem;
  }

  private deleteRisk(riesgoId: number) {
    const riskItem = this.dataRiskMap.find(x => x.riesgos.find(r => r.riesgoId === riesgoId));
    if (riskItem) {
      const indexRisk = this.dataRiskMap.indexOf(riskItem);

      if (riskItem.riesgos.length === 1) { // Sólo hay este => hay que eliminar todo el registro
        this.dataRiskMap.splice(this.dataRiskMap.indexOf(riskItem), 1);
      } else {
        const riesgoEliminar: IRiesgoDTO | undefined = riskItem.riesgos.find(r => r.riesgoId === riesgoId);

        if (riesgoEliminar) {
          riskItem.riesgos.splice(riskItem.riesgos.indexOf(riesgoEliminar), 1); // Eliminamos el riesgo
        }

        riskItem.codigo = this.getCodigoDataRiskMap(riskItem.riesgos);
        riskItem.etiqueta = this.getEtiquetaDataRiskMap(riskItem.riesgos);

        if (riskItem.riesgos.length === 1) { // Recuperamos el símbolo que le corresponde
          const estilo: IStyleSimbolRiskMapDTO | undefined = this.stilesDataRiskMapType.find(x => x.categoria === riskItem.riesgos[0].categoriaId);

          if (estilo) {
            riskItem.simbolo = estilo.simbolo;
            riskItem.simbAlto = this.simbolsStyles?.find(x => x.simbolo === estilo.simbolo)?.size ?? 0;
            riskItem.simbAncho = riskItem.simbAlto;
            riskItem.simbPosEtiq = this.simbolsStyles?.find(x => x.simbolo === estilo.simbolo)?.etiqPos ?? '';
            riskItem.simbColor = estilo.color;
          }
        } else {
          riskItem.simbAlto = this.calculaAltoMultiPunto(riskItem.riesgos.length, riskItem.simbolo);
          riskItem.simbAncho = this.calculaAnchoMultiPunto(riskItem.riesgos.length, riskItem.simbolo);
        }
        this.dataRiskMap[indexRisk] = riskItem;
      }
    }
  }

  private calculaAnchoMultiPunto(numRegistros: number, simbolo: string): number {
    const size = this.simbolsStyles?.find(x => x.simbolo === simbolo)?.size;
    if (size) {
      if (numRegistros < this.numRegLine) {
        return numRegistros * size;
      }
      return this.numRegLine * size;
    }
    return 0;
  }

  private calculaAltoMultiPunto(numRegistros: number, simbolo: string): number {
    const size = this.simbolsStyles?.find(x => x.simbolo === simbolo)?.size;
    if (size) {
      if (numRegistros < this.numRegLine) {
        return size;
      }
      return size * (Math.floor(numRegistros / this.numRegLine) + (numRegistros % this.numRegLine === 0 ? 0 : 1));
    }
    return 0;
  }

  private getCodigoDataRiskMap(riesgos: Array<IRiesgoDTO>): string {
    let codigo = '';
    let numReg = 0;

    riesgos.forEach(r => {
      numReg++;
      if (numReg > this.numRegLine) {
        codigo += '\r\n';
        numReg = 1;
      } else if (codigo.length > 0) {
        codigo += '-';
      }
      codigo += r.riesgoId.toString();
    });

    return codigo;
  }

  private getEtiquetaDataRiskMap(riesgos: Array<IRiesgoDTO>): string {
    let etiqueta = '';

    riesgos.forEach(r => {
      if (etiqueta.length > 0) {
        etiqueta += '\r\n';
      }
      etiqueta += `${r.riesgoId.toString()}-${r.categoriaDesc}`;
    });

    return etiqueta;
  }

  private calculaColor(probabilidad: number | null, impacto: number | null): string {
    if (typeof probabilidad === 'undefined' || probabilidad === null
      || typeof impacto === 'undefined' || impacto === null) {
      return this.gris;
    }

    if (probabilidad <= 1) {
      if (impacto <= 3) {
        return this.verde;
      } else if (impacto && impacto <= 5) {
        return this.amarillo;
      }
      return this.gris;
    } else if (probabilidad <= 2) {
      if (impacto <= 2) {
        return this.verde;
      } else if (impacto <= 4) {
        return this.amarillo;
      } else if (impacto <= 5) {
        return this.naranja;
      }
      return this.gris;
    } else if (probabilidad <= 3) {
      if (impacto <= 1) {
        return this.verde;
      } else if (impacto <= 3) {
        return this.amarillo;
      } else if (impacto <= 4) {
        return this.naranja;
      } else if (impacto <= 5) {
        return this.rojo;
      }
      return this.gris;
    } else if (probabilidad <= 4) {
      if (impacto <= 2) {
        return this.amarillo;
      } else if (impacto <= 3) {
        return this.naranja;
      } else if (impacto <= 5) {
        return this.rojo;
      }
      return this.gris;
    } else if (probabilidad <= 5) {
      if (impacto <= 1) {
        return this.amarillo;
      } else if (impacto <= 2) {
        return this.naranja;
      } else if (impacto <= 5) {
        return this.rojo;
      }
      return this.gris;
    }
    return this.gris;
  }

  public getChartOptions(data: Array<IDataRiskMapDTO>): EChartsOption {
    const dataMap = new Array<any>();
    // let chartOptions: EChartOption | null = null;
    // TODO: Review
    let chartOptions: any = null;

    data.forEach((x) => dataMap.push({
      name: x.codigo,
      value: [x.impacto, x.probabilidad],
      symbol: x.simbolo,
      symbolSize: [x.simbAncho, x.simbAlto],
      itemStyle: { color: x.simbColor },
      emphasis: {
        label: {
          show: true,
          formatter: function () {
            return x.etiqueta;
          },
          position: 'top',
          fontWeight: 'bold',
          color: '#000000'
        }
      },
      label: {
        show: true,
        position: 'inside',
        verticalAlign: x.simbPosEtiq,
        formatter: (params: any) => params.data.name
      }
    }));

    chartOptions = {
      series: {
        data: dataMap,
        type: 'scatter'
      },
      grid: {
        height: '80%',
        width: '80%',
        show: false
      },
      xAxis: {
        name: 'Impacto',
        nameLocation: 'center',
        nameTextStyle: {
          color: '#3C0F11',
          fontWeight: 'bold',
          fontSize: '18'
        },
        show: true,
        type: 'value',
        min: 0,
        max: 5,
        splitNumber: 5,
        axisLabel: {
          show: false
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {
          lineStyle: {
            width: '6',
            color: 'white'
          }
        }
      },
      yAxis: {
        name: 'Probabilidad',
        nameLocation: 'center',
        nameTextStyle: {
          color: '#3C0F11',
          fontWeight: 'bold',
          fontSize: '18'
        },
        show: true,
        type: 'value',
        min: 0,
        max: 5,
        splitNumber: 5,
        axisLabel: {
          show: false
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {
          lineStyle: {
            width: '6',
            color: 'white'
          }
        }
      }
    };

    return chartOptions;
  }
}
