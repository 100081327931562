import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CookieManagerService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly MAPA_RIESGOS_ARTAI_COOKIES_KEY: string = 'CookiesMapaRiesgosArtai';

  constructor(private cookieService: CookieService) {
  }

  aceptarCookies() {
    this.cookieService.set(this.MAPA_RIESGOS_ARTAI_COOKIES_KEY, 'Cookies Aceptadas');
  }

  eliminarCookies() {
    this.cookieService.deleteAll();
  }

  avisoCookiesAceptado(): boolean {
    return this.cookieService.check(this.MAPA_RIESGOS_ARTAI_COOKIES_KEY);
  }
}
