import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import browser from 'browser-detect';
import { CookieManagerService } from 'src/app/core/services/ui';

@Component({
  selector: 'howden-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public validBrowser = false;
  public mostrarAvisoCookies = true;
  public currentIndex = 0;
  public speed = 20000;
  public autoplay = true;
  public infinite = true;
  public avatars = new Array<any>();
  public images: string[] = ['psychology', 'brightness_alert', 'dynamic_form', 'browse_activity'];
  public messages: string[] = [
    'Los servicios que el Grupo Howden presta están sustentados a partir de la aplicación de técnicas de Gestión de Riesgos que sirvan de apoyo, asesoramiento, y aporten valor a la Gerencia de Riesgos de la Empresa.',
    'Los acontecimientos que estamos viviendo los últimos años (pandemias, tensiones geopolíticas, guerras comerciales, sanciones, ciber delincuencia, interrupciones en las cadenas de suministros, transición energética, cambio climático, inteligencia artificial,…) generan unas situaciones de altas incertidumbres, más allá de los riesgos tradicionalmente asegurables (daños materiales, responsabilidades, transportes, etc.). Este entorno está obligando a las empresas a estar preparadas para ampliar su percepción de los riesgos a ámbitos que antes muy rara vez se analizaban.',
    'En este entorno creemos es imprescindible contar con un apoyo externo que aporte el expertise y las herramientas necesarias para poner en manos de la Empresa una metodología adecuada para la toma de decisiones informada y que ayude a detectar y minimizar el impacto de los riesgos, y optimizar su transferencia.',
    'El Grupo Howden, como conocedor del tejido empresarial y de este entorno, promueve la confección de Mapas de Riesgos, y la necesidad de su actualización. Para ello, como primer diagnóstico ponemos a disposición de las empresas y administraciones públicas esta herramienta de Evaluación Preliminar de Riesgos.'
  ];

  constructor(private router: Router, private cookiesSrv: CookieManagerService) {
    // Avatar list
    for (let i = 0; i < this.images.length; i++) {
      this.avatars.push({ urlImage: this.images[i], message: this.messages[i] });
    }
  }

  ngOnInit() {
    // Comprobamos el navegador
    const dataBrowser = browser();
    if (dataBrowser.name === 'chrome' || dataBrowser.name === 'firefox') {
      this.validBrowser = true;
    } else {
      alert('Navegadores recomendados: Chrome y Mozilla');
      this.validBrowser = false;
    }

    // Comprobamos el aviso de cookies
    this.mostrarAvisoCookies = !this.cookiesSrv.avisoCookiesAceptado();
  }

  indexChanged(index: any) {
    // eslint-disable-next-line no-console
    console.log(index);
  }

  goToRiskMap() {
    this.router.navigate(['/risks-evaluation']);
  }

  aceptarCookies() {
    this.cookiesSrv.aceptarCookies();
    this.mostrarAvisoCookies = false;
  }

  borrarCookies() {
    this.cookiesSrv.eliminarCookies();
    this.router.navigate(['/cookie-delete']);
  }
}
