<div class="container flex f-fd--column-md padding-10">
  <div class="f-basis--6-lg f-basis--12">
    <h1>Mis Riesgos</h1>
    <mat-tab-group>
      <mat-tab label="Selección de Riesgos">
        <howden-risks-steps></howden-risks-steps>
      </mat-tab>
      <mat-tab label="Riesgos Seleccionados">
        <howden-risks-selection></howden-risks-selection>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="f-basis--6-lg f-basis--12">
    <h1>
      Mapa de Riesgos
      <button
        mat-icon-button
        color="primary"
        aria-label="helpRiskMap"
        title="Ayuda para la realización del mapa"
        (click)="helpRiskMap()"
      >
        <mat-icon>help</mat-icon>
      </button>
    </h1>
    <div class="flex">
      <div class="f-basis--12">
        <howden-risks-map></howden-risks-map>
      </div>
    </div>
    <div class="flex">
      <div class="f-basis--12 text-center btn-container">
        <button
          mat-raised-button
          color="filler"
          [disabled]="!childSteps.compruebaFlujoCompleto()"
          (click)="downloadRiskReport()"
        >
          Descargar Informe de Riesgos
        </button>
      </div>
    </div>
  </div>
</div>
