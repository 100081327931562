import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from '../../constants';
import { IInformeDTO } from '../../models/api';

@Injectable({
  providedIn: 'root'
})
export class InformeService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  public generarInforme(datos: IInformeDTO): Observable<Blob> {
    return this.httpClient.postRespBlob(APP_CONSTANTS.API_MAPA_RIESGOS, `/informes/generar`, datos, { responseType: 'blob' });
  }
}
