<div class="flex f-gap--10px">
  <div class="f-basis--3">
    <div class="click-area">
      <button mat-raised-button color="filler" title="Paso Anterior" matStepperPrevious *ngIf="!step.primero">
        <i class="material-symbols-outlined">keyboard_arrow_left</i>
      </button>
    </div>
  </div>
  <div class="f-basis--6 text-center">
    <h3>Riesgos del Tipo {{ step.descripcion }}</h3>
  </div>
  <div class="f-basis--3 text-right btn-container">
    <button
      mat-raised-button
      color="filler"
      *ngIf="step.ultimo && !step.otros"
      (click)="addStepOtros()"
    >
      Añadir Otros Riesgos
    </button>
    <button
      mat-raised-button
      color="filler"
      title="Siguiente Paso"
      matStepperNext
      *ngIf="!step.ultimo"
      [disabled]="!step.completo"
    >
      <i class="material-symbols-outlined">keyboard_arrow_right</i>
    </button>
  </div>
</div>
<div class="flex">
  <div class="f-basis--12" *ngIf="!step.otros">
    <div class="table-container">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortActive="riesgoId"
        matSortDirection="asc"
        class="mat-elevation-z8 table table-responsive"
      >
        <ng-container matColumnDef="riesgoId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
          <td mat-cell *matCellDef="let element">{{ element.riesgoId }}</td>
        </ng-container>
        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Evento</th>
          <td mat-cell *matCellDef="let element">
            <div class="flex f-ai--center">
              <button mat-icon-button color="primary" aria-label="helpRiesgo" title="Ejemplos" (click)="helpRiesgo(element)">
                <mat-icon>help</mat-icon>
              </button>
              <span>{{ element.nombre }}</span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="probabilidad">
          <th mat-header-cell *matHeaderCellDef>
            Probabilidad
            <button
              mat-icon-button
              color="primary"
              aria-label="helpProbabilidad"
              title="Ayuda para los valores a asignar"
              (click)="helpProbabilidad()"
            >
              <mat-icon>help</mat-icon>
            </button>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field [style.display]="discardedRisks.indexOf(element) > -1 ? 'none' : 'block'" appearance="standard">
              <input
                matInput
                type="number"
                step="0.5"
                min="0"
                max="5"
                [(ngModel)]="element.probabilidad"
                [ngModelOptions]="{ standalone: true }"
                (change)="updateDataRiskMapProbabilidad(element)"
              />
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="impacto">
          <th mat-header-cell *matHeaderCellDef>
            Impacto
            <button
              mat-icon-button
              color="primary"
              aria-label="helpImpacto"
              title="Ayuda para los valores a asignar"
              (click)="helpImpacto()"
            >
              <mat-icon>help</mat-icon>
            </button>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field [style.display]="discardedRisks.indexOf(element) > -1 ? 'none' : 'block'" appearance="standard">
              <input
                matInput
                type="number"
                step="0.5"
                min="0"
                max="5"
                [(ngModel)]="element.impacto"
                [ngModelOptions]="{ standalone: true }"
                (change)="updateDataRiskMapImpacto(element)"
              />
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>Descartado</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox
              *ngIf="discardedRisks.indexOf(element) > -1"
              checked="true"
              #checkbox="matCheckbox"
              (change)="selectRisk(checkbox.checked, element)"
            >
            </mat-checkbox>
            <mat-checkbox
              *ngIf="discardedRisks.indexOf(element) < 0"
              #checkbox="matCheckbox"
              (change)="selectRisk(checkbox.checked, element)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
      </table>
    </div>
  </div>
</div>
<div class="flex">
  <div class="f-basis--12" *ngIf="step.otros">
    <howden-risks-others></howden-risks-others>
  </div>
</div>
