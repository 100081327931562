<div class="flex">
  <div class="f-basis--12">
    <div class="table-container margin-top-25">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortActive="orden"
        matSortDirection="desc"
        class="mat-elevation-z8 table table-responsive"
      >
        <ng-container matColumnDef="riesgoId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
          <td mat-cell *matCellDef="let element">{{ element.riesgoId }}</td>
        </ng-container>
        <ng-container matColumnDef="categoriaDesc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Categoría</th>
          <td mat-cell *matCellDef="let element">{{ element.categoriaDesc }}</td>
        </ng-container>
        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Evento</th>
          <td mat-cell *matCellDef="let element">{{ element.nombre }}</td>
        </ng-container>
        <ng-container matColumnDef="probabilidad">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Probabilidad</th>
          <td mat-cell *matCellDef="let element" class="text-center">{{ element.probabilidad }}</td>
        </ng-container>
        <ng-container matColumnDef="impacto">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Impacto</th>
          <td mat-cell *matCellDef="let element" class="text-center">{{ element.impacto }}</td>
        </ng-container>
        <ng-container matColumnDef="orden">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let element">
            <i class="material-symbols-outlined" [style.color]="element.color">stop_circle</i>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>
