<form [formGroup]="downloadReportForm" (ngSubmit)="createAndDownloadReport()">
  <div mat-dialog-content>
    <input type="hidden" id="imagenMapaBase64" />
    <div class="flex">
      <div class="f-basis--4">
        <div class="logo"><img src="/assets/images/logo.png" /></div>
      </div>
      <div class="f-basis--8">
        <h1>Descarga tu Evaluación de Riesgos</h1>
        <h3>Para ello sólo tendrás que introducir algunos datos de contacto</h3>
      </div>
    </div>
    <div class="flex">
      <div class="f-basis--4">
        <div><img src="/assets/images/report-cool.png" /></div>
      </div>
      <div class="f-basis--8">
        <div class="flex">
          <div class="f-basis--12">
            <mat-form-field>
              <mat-label>Razón Social</mat-label>
              <input matInput placeholder="Razón Social" autofocus formControlName="razonSocial" />
            </mat-form-field>
          </div>
        </div>
        <div class="flex f-gap--10px">
          <div class="f-basis--4">
            <mat-form-field>
              <mat-label>CIF</mat-label>
              <input matInput placeholder="CIF" formControlName="cif" />
            </mat-form-field>
          </div>
          <div class="f-basis--8">
            <mat-form-field>
              <mat-label>Correo Electrónico de Contacto</mat-label>
              <input matInput placeholder="Correo Electrónico de Contacto" formControlName="email" />
            </mat-form-field>
          </div>
        </div>
        <div class="flex f-gap--10px">
          <div class="f-basis--6">
            <mat-form-field>
              <mat-label>Teléfono de Contacto</mat-label>
              <input matInput placeholder="Teléfono de Contacto" formControlName="telefono" />
            </mat-form-field>
          </div>
          <div class="f-basis--6">
            <mat-form-field>
              <mat-label>Localidad</mat-label>
              <input
                matInput
                placeholder="Localidad"
                formControlName="localidad"
                [matAutocomplete]="autoLocalidad"
              />
              <mat-autocomplete #autoLocalidad="matAutocomplete">
                <mat-option
                  *ngFor="let localidad of localidadesFiltradas | async | sort : 'descripcion'"
                  [value]="localidad.descripcion"
                >
                  {{ localidad.descripcion }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <div class="flex f-gap--10px">
          <div class="f-basis--12">
            <mat-checkbox class="inline" formControlName="politicaPrivacidad">
              <a
                href="https://www.howdeniberia.com/politica-de-privacidad/"
                title="Consulta Política de Privacidad"
                target="_blank"
                mat-menu-item
              >
                Acepto la Política de Privacidad
              </a>
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="btn-container flex f-ai--center f-jc--center">
    <button mat-button mat-dialog-close mat-raised-button>Volver a la Evaluación</button>
    <button mat-raised-button [disabled]="downloadReportForm.invalid" type="submit" color="filler">Descargar</button>
  </div>
</form>
